// components/user/Button.js
import React from "react";
import { Button as MaterialButton } from "@mui/material";
import { useNode } from "@craftjs/core";

interface ButtonProps {
  size?: "small" | "medium" | "large" | undefined;
  variant?: "text" | "outlined" | "contained" | undefined;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  text?: string;
  children?: any;
}

export const Button = ({ size, variant, color, text }: ButtonProps) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  return (
    <MaterialButton
      ref={(ref) => ref && connect(drag(ref))}
      size={size}
      variant={variant}
      color={color}
    >
      {text}
    </MaterialButton>
  );
};
