import React from "react";
import { Box } from "@mui/material";
import { useNode } from "@craftjs/core";

export const Block = ({ children }: { children?: React.ReactElement }) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  return (
    <Box
      minHeight={30}
      border="1px grey dotted"
      ref={(ref: HTMLElement) => ref && connect(drag(ref))}
    >
      {children}
    </Box>
  );
};
