// components/Toolbox.js
import React from "react";
import { Box, Typography, Grid, Button as MaterialButton } from "@mui/material";
import { Element, useEditor } from "@craftjs/core";
import { Container } from "../Container";
import { Card } from "../Card";
import { Button } from "../Button";
import { Text } from "../Text";
import { Table } from "../Table";
import { Block } from "../Block";

export const Toolbox = () => {
  const { connectors, query } = useEditor();

  return (
    <Box px={2} py={2}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyItems="center"
        spacing={1}
      >
        <Box pb={2}>
          <Typography>Drag to add</Typography>
        </Box>
        <Grid container direction="column" item>
          <MaterialButton
            ref={(ref) =>
              ref && connectors.create(ref, <Element is={Block} canvas />)
            }
            variant="contained"
          >
            Block
          </MaterialButton>
        </Grid>
        <Grid container direction="column" item>
          <MaterialButton
            ref={(ref) =>
              ref &&
              connectors.create(
                ref,
                <Button
                  variant="contained"
                  color="primary"
                  text="Click me"
                  size="small"
                />
              )
            }
            variant="contained"
          >
            Button
          </MaterialButton>
        </Grid>
        <Grid container direction="column" item>
          <MaterialButton
            ref={(ref) =>
              ref && connectors.create(ref, <Text text="Hi world" />)
            }
            variant="contained"
          >
            Text
          </MaterialButton>
        </Grid>
        <Grid container direction="column" item>
          <MaterialButton
            ref={(ref) => ref && connectors.create(ref, <Table data="" />)}
            variant="contained"
          >
            Data table
          </MaterialButton>
        </Grid>
      </Grid>
    </Box>
  );
};

{
  /* <Grid container direction="column" item>
          <MaterialButton
            ref={(ref) =>
              ref &&
              connectors.create(
                ref,
                <Element is={Container} padding={20} canvas />
              )
            }
            variant="contained"
          >
            Container
          </MaterialButton>
        </Grid> */
}
