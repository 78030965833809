import React from "react";
import { Box, Typography } from "@mui/material";
import { ActionButtons } from "../ActionButtons";

export const Header = () => (
  <Box px={2} boxShadow="rgb(0 0 0 / 7%) 0px -1px inset">
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Typography variant="h6">Page Builder</Typography>
      </Box>
      <Box>
        <ActionButtons />
      </Box>
    </Box>
  </Box>
);
