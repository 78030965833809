import { RESTQueryResult } from "../types";
import { getError } from "./getError";
import { stripDelimiters } from "./stripDelimiters";

interface ExecuteQueryResultExpressionResult {
  isError: boolean;
  error?: Error;
  result?: any;
}

const isAValidQueryResultExpression = (
  queryResultExpression: string
): boolean => {
  return /^[a-zA-Z0-9.[\]]*$/.test(queryResultExpression);
};

const queryResultToVariable = (queryResult: RESTQueryResult) =>
  Object.keys(queryResult)
    .map(
      (key) =>
        `var ${key} = ${(() => {
          switch (typeof queryResult[key]) {
            case "object":
              return JSON.stringify(queryResult[key]);
            default:
              return "";
          }
        })()}`
    )
    .join("; ");

export const executeQueryResultExpression = (
  queryResult: RESTQueryResult,
  expressionWithDelimiter: string
): ExecuteQueryResultExpressionResult => {
  const queryResultExpression = stripDelimiters(expressionWithDelimiter);

  if (!isAValidQueryResultExpression(queryResultExpression)) {
    return {
      isError: true,
      error: new Error("Invalid query result expression"),
    };
  }

  const funcBody = `${queryResultToVariable(
    queryResult
  )}; return ${stripDelimiters(expressionWithDelimiter)}`;

  try {
    const func = new Function(funcBody);
    const result = func();
    return {
      isError: false,
      result,
    };
  } catch (error) {
    return {
      isError: true,
      error: getError(error),
    };
  }
};
