// components/Topbar.js
import React, { useState } from "react";
import { Box, Grid, Button, Alert, Snackbar } from "@mui/material";
import { useEditor } from "@craftjs/core";
import lz from "lzutf8";

export const ActionButtons = () => {
  const { actions, query, enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSave = () => {
    const json = query.serialize();
    const compressedJson = lz.encodeBase64(lz.compress(json));
    localStorage.setItem("page", compressedJson);
    setOpenSnackbar(true);
    setSnackbarMessage("Saved page to local storage");
  };

  const handleDelete = () => {
    localStorage.removeItem("page");
    setOpenSnackbar(true);
    setSnackbarMessage("Page removed from local storage");
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box>
      <Box px={1} p={1} mb={1} display="flex" justifyContent="flex-end">
        <Box mr={1}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
        <Box>
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={handleDelete}
          >
            Remove
          </Button>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};
