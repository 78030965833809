// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";

import { Toolbox } from "./components/Toolbox";
import { SettingsPanel } from "./components/SettingsPanel";

import { Container } from "./components/Container";
import { Button } from "./components/Button";
import { Card } from "./components/Card";
import { Text } from "./components/Text";
import { Table } from "./components/Table";
import { Block } from "./components/Block";
import { Header } from "./components/Header";

import { Editor, Frame, Element } from "@craftjs/core";

import lz from "lzutf8";
import { QueryPanel } from "./components/QueryPanel";
import { QueryResultProvider } from "./contexts";

export default function UIBuilder() {
  const [json, setJson] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const data = localStorage.getItem("page");
    if (data) {
      const json = lz.decompress(lz.decodeBase64(data));
      setJson(json);
    }

    setIsLoading(false);
  }, []);

  return (
    <QueryResultProvider>
      <Editor
        resolver={{ Card, Button, Text, Container, Table, Block }}
        enabled
      >
        <Header />
        <Grid container spacing={0}>
          <Grid item xs>
            {!isLoading && (
              <Frame data={json}>
                <Element is={Container} background="#f4f4f4" canvas></Element>
              </Frame>
            )}
          </Grid>
          <Grid item xs={2}>
            <Box boxShadow="rgb(0 0 0 / 7%) 1px 0px inset">
              <Box boxShadow="rgb(0 0 0 / 7%) 0px -1px inset">
                <Toolbox />
              </Box>
              <Box boxShadow="rgb(0 0 0 / 7%) 0px -1px inset">
                <SettingsPanel />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Editor>
      <QueryPanel />
    </QueryResultProvider>
  );
}
