// components/SettingsPanel.js
import React from "react";
import {
  Box,
  Typography,
  Button as MaterialButton,
  IconButton,
} from "@mui/material";
import { useEditor } from "@craftjs/core";
import { Delete } from "@mui/icons-material";

export const SettingsPanel = () => {
  const { actions, selected, isEnabled } = useEditor((state, query) => {
    const currentNodeId = query.getEvent("selected").last();
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return {
      selected,
      isEnabled: state.options.enabled,
    };
  });

  const deleteSelected = () => {
    selected && actions.delete(selected.id);
  };

  return (
    selected && (
      <Box py={2} display="flex" flexDirection="column">
        <Box mb={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={2}
          >
            <Box>
              <Typography>{selected.name}</Typography>
            </Box>
            {selected && selected.isDeletable && (
              <Box>
                <IconButton size="small" onClick={deleteSelected}>
                  <Delete fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>

        {selected.settings && (
          <Box data-cy="settings-panel" px={2}>
            <Box mb={2}>
              <Typography>Settings</Typography>
            </Box>
            {React.createElement(selected.settings)}
          </Box>
        )}
      </Box>
    )
  );
};
