import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNode } from "@craftjs/core";
import { Box, FormControl, FormLabel, TextareaAutosize } from "@mui/material";
import { useExecuteQueryResultExpression } from "../../hooks/useExecuteQueryResultExpression";

interface TableProps {
  data: string;
}
interface TableSettingsProps {
  data: string;
}

export function Table({ data }: TableProps) {
  const {
    connectors: { connect, drag },
  } = useNode();

  const { isError, result } = useExecuteQueryResultExpression(data);

  const getRows = () => {
    if (isError || !Array.isArray(result)) return [];
    return result;
  };

  const getColumns = (): GridColDef[] => {
    const rows = getRows();
    if (rows.length === 0) return [];
    const firstRow = rows[0];
    return Object.keys(firstRow).map((key) => ({
      field: key,
      headerName: key,
    }));
  };

  return (
    <div
      style={{ height: 400, width: "100%" }}
      ref={(ref) => ref && connect(drag(ref))}
    >
      <DataGrid
        rows={getRows()}
        columns={getColumns()}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
      />
    </div>
  );
}

const TableSettings = () => {
  const {
    actions: { setProp },
  } = useNode((node) => ({
    data: node.data.props.data,
  }));

  return (
    <Box mb={2}>
      <FormControl size="small" component="fieldset">
        <Box>
          <FormLabel component="legend">Text</FormLabel>
          <TextareaAutosize
            placeholder="Enter data"
            onChange={(ev) => {
              const value = ev.target.value;
              setProp(
                (props: TableSettingsProps) => (props.data = value),
                1000
              );
            }}
          />
        </Box>
      </FormControl>
    </Box>
  );
};

Table.craft = {
  related: {
    settings: TableSettings,
  },
};
