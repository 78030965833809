import { useContext } from "react";
import { QueryResultContext } from "../../contexts";
import { executeQueryResultExpression } from "../../utils/executeQueryResultExpression";
import { hasExpressionDelimiters } from "../../utils/hasExpressionDelimiters";

export const useExecuteQueryResultExpression = (expression: string) => {
  const queryResult = useContext(QueryResultContext);

  if (hasExpressionDelimiters(expression)) {
    const { isError, result } = executeQueryResultExpression(
      queryResult,
      expression
    );
    return {
      isError,
      result,
    };
  }

  return {
    isError: true,
  };
};
