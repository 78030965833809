import React from "react";
import { useNode } from "@craftjs/core";
import {
  FormControl,
  FormLabel,
  Slider,
  Box,
  TextareaAutosize,
} from "@mui/material";
import { useExecuteQueryResultExpression } from "../../hooks/useExecuteQueryResultExpression";

interface TextProps {
  text: string;
  fontSize?: number;
}

interface TextSettingsProps {
  text: string;
  fontSize: number | number[];
}

export const Text = ({ text, fontSize }: TextProps) => {
  const {
    connectors: { connect, drag },
  } = useNode();

  const { isError, result } = useExecuteQueryResultExpression(text);

  const getValue = (textValue: string) => {
    return isError || typeof result === "object" ? textValue : result;
  };

  return (
    <label ref={(ref) => ref && connect(drag(ref))} style={{ fontSize }}>
      {getValue(text)}
    </label>
  );
};

const TextSettings = () => {
  const {
    actions: { setProp },
    fontSize,
  } = useNode((node) => ({
    text: node.data.props.text,
    fontSize: node.data.props.fontSize,
  }));

  return (
    <Box mb={2}>
      <FormControl size="small" component="fieldset">
        <Box mb={1}>
          <FormLabel component="legend">Font size</FormLabel>
          <Slider
            value={fontSize || 16}
            step={7}
            min={1}
            max={50}
            onChange={(_, value) => {
              setProp(
                (props: TextSettingsProps) => (props.fontSize = value),
                1000
              );
            }}
          />
        </Box>
        <Box>
          <FormLabel component="legend">Text</FormLabel>
          <TextareaAutosize
            placeholder="Enter text"
            onChange={(ev) => {
              const value = ev.target.value;
              setProp((props: TextSettingsProps) => (props.text = value), 1000);
            }}
          />
        </Box>
      </FormControl>
    </Box>
  );
};

Text.defaultProps = {
  fontSize: 16,
};

Text.craft = {
  related: {
    settings: TextSettings,
  },
};
