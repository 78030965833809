import { QueriesResults, useQueries, UseQueryResult } from "react-query";
import { getQueryResult } from "../../api/getQueryResult";
import { RESTQueries, RESTQueryResult } from "../../types";

interface QueryResultResponse {
  [key: string]: UseQueryResult;
}

export const useQueryResult = (queries: RESTQueries): QueryResultResponse => {
  const queryKeys = Object.keys(queries);
  const queryResults: QueriesResults<any> = useQueries(
    queryKeys.map((key) => {
      return {
        queryKey: ["queryResult", key],
        queryFn: () => getQueryResult(queries[key].httpRequestOption),
        enabled: queryKeys.length > 0,
      };
    })
  );

  const queryResultMap = queryResults.reduce(
    (queryResult: RESTQueryResult, currResult: UseQueryResult, i: number) => {
      queryResult[queryKeys[i]] = currResult;
      return queryResult;
    },
    {}
  );

  return queryResultMap;
};
