import { useState, useEffect } from "react";
import queryJSON from "../../query.json";

export const useQuerySettings = () => {
  const [queries, setQueries] = useState({});

  const getQueries = async () => {
    setQueries(queryJSON);
  };

  useEffect(() => {
    getQueries();
  }, []);

  return {
    queries,
  };
};
