import React from "react";
import { Paper } from "@mui/material";
import { useNode } from "@craftjs/core";

export const Container = ({ background, padding = 0, children }: any) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  return (
    <Paper
      elevation={0}
      ref={(ref) => ref && connect(drag(ref))}
      style={{background, padding: `${padding}px`, minHeight: 500 }}
    >
      {children}
    </Paper>
  );
};
