import { createContext, ReactElement } from "react";
import { useQueryResult } from "../hooks/useQueryResult";
import { useQuerySettings } from "../hooks/useQuerySettings";

const QueryResultContext = createContext<any>({});

function QueryResultProvider({ children }: { children: ReactElement }) {
  const { queries } = useQuerySettings();
  const queryResult = useQueryResult(queries);

  return (
    <QueryResultContext.Provider value={queryResult}>
      {children}
    </QueryResultContext.Provider>
  );
}

export { QueryResultProvider, QueryResultContext };
